<template>
    <v-container
    class="text-center"
    fill-height
  >
    <v-row align="center">
      <v-col>
        <h1 class="text-h1 primary--text">Ops, 404</h1>

        <p class="text-h5 mt-4">A página não foi encontrada!</p>

        <v-btn
          :to="`/`"
          color="primary"
          outlined
        >
          Voltar para o início!
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'AccessDenied'
}
</script>